



$(window).on('load', function() {
    // remove overlay after loaded page
    $('.content-overlay').removeClass('overlay-block').find('.overlay-layer').remove();

});


const { FormatMoney } = require('format-money-js')

window.formatMoney = new FormatMoney({decimals: 0})