

setTimeout(() => {
    // trigger tooltip
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()


        $('.dropdown-toggle').dropdown();
    })
}, 1000)
